import React from 'react';
import { AddFieldProps } from '../types';
import { Field } from '../../field/field';

export const SilboUseField = ({ fields, formik, schemas }: AddFieldProps) => {
  const silboUseList = [
    {
      id: 'Gastos cotidianos',
      value: 'Gastos cotidianos',
    },
    {
      id: 'Proyectos personales',
      value: 'Proyectos personales',
    },
    {
      id: 'Actividades profesionales',
      value: 'Actividades profesionales',
    },
    {
      id: 'Compra online y comercio electrónico',
      value: 'Compra online y comercio electrónico',
    },
  ];

  const silboUseField = fields?.silboUse!;

  if (silboUseField?.required || silboUseField?.defaultValue) {
    schemas.silboUse = schemas?.silboUse!.required('El campo Fuente de ingresos es requerido.');
  }

  return (
    <Field
      key="silboUse"
      label="Finalidad prevista del uso de Silbo"
      required={silboUseField?.required}
      warning={formik?.errors.occupation}
      readOnly={silboUseField?.readOnly}
    >
      <select
        className="w-full appearance-none bg-transparent font-body text-base leading-tight text-primary focus:outline-none focus-visible:outline-none"
        value={formik?.values.silboUse ?? ''}
        onChange={formik?.handleChange('silboUse') || (() => ({}))}
        disabled={silboUseField?.readOnly}
      >
        <option value="" disabled>
          Seleccionar uso de Silbo
        </option>
        {silboUseList.map(({ id, value }: { id: string; value: string }) => (
          <option key={id} value={id}>
            {value}
          </option>
        ))}
      </select>
    </Field>
  );
};
