import React from 'react';
import { AddFieldProps } from '../types';
import { Field } from '../../field/field';

export const IncomeSourceField = ({ fields, formik, schemas }: AddFieldProps) => {
  const incomeSourceList = [
    {
      id: 'Rentas de trabajo',
      value: 'Rentas de trabajo',
    },
    {
      id: 'Rentas de alquiler',
      value: 'Rentas de alquiler',
    },
    {
      id: 'Prestaciones del gobierno',
      value: 'Prestaciones del gobierno',
    },
    {
      id: 'Pensión',
      value: 'Pensión',
    },
    {
      id: 'Subveciones',
      value: 'Subveciones',
    },
    {
      id: 'Donaciones',
      value: 'Donaciones',
    },
    {
      id: 'Ingresos familiares',
      value: 'Ingresos familiares',
    },
    {
      id: 'Ingresos del mercado de valores',
      value: 'Ingresos del mercado de valores',
    },
    {
      id: 'Ventas de activos',
      value: 'Ventas de activos',
    },
    {
      id: 'Herencias',
      value: 'Herencias',
    },
    {
      id: 'Ganancias de juego',
      value: 'Ganancias de juego',
    },
    {
      id: 'Otros',
      value: 'Otros',
    },
  ];

  const incomeSourceField = fields?.incomeSource!;

  if (incomeSourceField?.required || incomeSourceField?.defaultValue) {
    schemas.incomeSource = schemas?.incomeSource!.required('El campo Fuente de ingresos es requerido.');
  }

  return (
    <Field
      key="incomeSource"
      label="Principal fuente de ingresos"
      required={incomeSourceField?.required}
      warning={formik?.errors.occupation}
      readOnly={incomeSourceField?.readOnly}
    >
      <select
        className="w-full appearance-none bg-transparent font-body text-base leading-tight text-primary focus:outline-none focus-visible:outline-none"
        value={formik?.values.incomeSource ?? ''}
        onChange={formik?.handleChange('incomeSource') || (() => ({}))}
        disabled={incomeSourceField?.readOnly}
      >
        <option value="" disabled>
          Seleccionar fuente de ingresos
        </option>
        {incomeSourceList.map(({ id, value }: { id: string; value: string }) => (
          <option key={id} value={id}>
            {value}
          </option>
        ))}
      </select>
    </Field>
  );
};
