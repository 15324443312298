/**
 * Convert IncomingDTO to APP
 */

import { graphql, useStaticQuery } from 'gatsby';
import { useContext } from 'react';
import { LangContext } from '../../../../../context/lang.context';
import { ConfigFields, FieldName, Command, CustomerStatus } from '../types';

export type IncomingDTO = {
  form_data: {
    user_controls: Control[];
    steps?: Array<{ type: string; name: string }>;
  };
  command?: Command | any;
  customer_status?: CustomerStatus | any;
  expiration_time?: number;
  remaning_time_to_timeout?: number;
};

type Control = {
  name: ControlName;
  required: boolean;
  value: string | any;
  read_only: boolean;
};

type ControlName =
  | 'NAME'
  | 'SURNAME'
  | 'GOVERNMENT_IDENTIFICATION'
  | 'GOVERNMENT_IDENTIFICATION_TYPE'
  | 'BIRTH_DATE'
  | 'GENDER'
  | 'TRIBUTE_KEY'
  | 'TRIBUTE_KEY_TYPE'
  | 'PIN'
  | 'EMAIL'
  | 'TERMS_AND_CONDITIONS'
  | 'CELLPHONE'
  | 'ALIAS'
  | 'ADDITIONAL_DATA'
  | string;

const names: { [key in ControlName]: FieldName } = {
  NAME: 'firstName',
  SURNAME: 'lastName',
  GOVERNMENT_IDENTIFICATION: 'documentNumber',
  GOVERNMENT_IDENTIFICATION_TYPE: 'documentType',
  BIRTH_DATE: 'birthDate',
  GENDER: 'gender',
  TRIBUTE_KEY: 'tributeNumber',
  TRIBUTE_KEY_TYPE: 'tributeType',
  PIN: 'pin',
  EMAIL: 'email',
  CELLPHONE: 'phone',
  ALIAS: 'alias',
  TERMS_AND_CONDITIONS: 'agreement',
};

const createConfigField = ({ value, required, read_only }: Control) => ({
  defaultValue: value,
  required,
  readOnly: read_only,
});

const createConfigFields = (controls: Control[]): ConfigFields =>
  controls.reduce((fields, control) => {
    if (control.name === 'ADDITIONAL_DATA') {
      const occupationField = control.value.find((d: any) => d.fieldName === 'OCCUPATION');
      fields['occupation'] = {
        defaultValue: occupationField?.value,
        required: occupationField?.required,
        readOnly: occupationField?.read_only,
      };
      const WorkAreaField = control.value.find((d: any) => d.fieldName === 'WORK_AREA');
      fields['workArea'] = {
        defaultValue: WorkAreaField?.value,
        required: WorkAreaField?.required,
        readOnly: WorkAreaField?.read_only,
      }
      const IncomeSourceField = control.value.find((d: any) => d.fieldName === 'INCOME_SOURCE');
      fields['incomeSource'] = {
        defaultValue: IncomeSourceField?.value,
        required: IncomeSourceField?.required,
        readOnly: IncomeSourceField?.read_only,
      }
      const SilboUseField = control.value.find((d: any) => d.fieldName === 'SILBO_USE');
      fields['silboUse'] = {
        defaultValue: SilboUseField?.value,
        required: SilboUseField?.required,
        readOnly: SilboUseField?.read_only,
      }
      const newsletterField = control.value.find((d: any) => d.fieldName === 'NEWSLETTER');
      fields['newsletter'] = {
        defaultValue: newsletterField?.value,
        required: newsletterField?.required,
        readOnly: newsletterField?.read_only,
      };
      console.log(fields);
    } else {
      fields[names[control.name]] = createConfigField(control);
    }
    return fields;
  }, {});

export const format = (dto: IncomingDTO) => {
  return {
    config: { fields: createConfigFields(dto.form_data.user_controls) },
    command: dto.command,
    customerStatus: dto.customer_status,
    expirationTime: dto.expiration_time,
    remainingTimeToTimeoput: dto.remaning_time_to_timeout,
  };
};

export const documentTypeList = () => {
  const { t } = useContext(LangContext);

  const data = useStaticQuery(graphql`
    query {
      allI18NJson {
        nodes {
          locale
          clientId
          USER_DETAIL {
            FORM {
              DOCUMENT_TYPE_COMBO {
                DNI
                PASSPORT
                IDENTIFICATION_CARD
                CIVIC_NOTEBOOK
                ENROLLMENT_BOOK
              }
            }
          }
        }
      }
    }
  `);
  return [
    { value: 'DNI', title: t(data).USER_DETAIL.FORM.DOCUMENT_TYPE_COMBO.DNI },
    { value: 'PASAPORTE', title: t(data).USER_DETAIL.FORM.DOCUMENT_TYPE_COMBO.PASSPORT },
    { value: 'CEDULA', title: t(data).USER_DETAIL.FORM.DOCUMENT_TYPE_COMBO.IDENTIFICATION_CARD },
    { value: 'LIBCIV', title: t(data).USER_DETAIL.FORM.DOCUMENT_TYPE_COMBO.CIVIC_NOTEBOOK },
    { value: 'LIBENR', title: t(data).USER_DETAIL.FORM.DOCUMENT_TYPE_COMBO.ENROLLMENT_BOOK },
  ];
};

export const getMask = (valueType: string, regex: string): RegExp[] => {
  if (valueType !== undefined) {
    if (valueType === 'PASAPORTE') {
      const pasportSlice = [0, 9, 10, 11, 12, 17];
      const regex1 = new RegExp(regex?.slice(pasportSlice[0], pasportSlice[1]) + '+$');
      const cantRegex1 = parseInt(regex?.slice(pasportSlice[2], pasportSlice[3]));
      const regex2 = new RegExp('^' + regex?.slice(pasportSlice[4], pasportSlice[5]) + '+$');
      const cantRegex2 = parseInt(regex?.slice(-3, -2));
      return [...Array(cantRegex1).fill(regex1), ...Array(cantRegex2).fill(regex2)];
    }
    if (valueType === 'DNI') {
      const dniSlice = [0, 6, 7, 8, 9, 17];
      const regex1 = new RegExp(regex?.slice(dniSlice[0], dniSlice[1]) + '+$');
      const cantRegex1 = parseInt(regex?.slice(dniSlice[2], dniSlice[3]));
      const regex2 = new RegExp('^' + regex?.slice(dniSlice[4], dniSlice[5]) + '+$');
      const cantRegex2 = parseInt(regex?.slice(-3, -2));
      return [...Array(cantRegex1).fill(regex1), ...Array(cantRegex2).fill(regex2)];
    }
    if (valueType === 'CEDULA' || valueType === 'LIBCIV' || valueType === 'LIBENR') {
      const cedulaSlice = [0, 6];
      const regex1 = new RegExp(regex?.slice(cedulaSlice[0], cedulaSlice[1]) + '+$');
      const cantRegex1 = parseInt(regex?.slice(-3, -2));
      return Array(cantRegex1).fill(regex1);
    }
    if (valueType === 'NIF') {
      const cuitSlice = [0, 6, 7, 8, 9, 17];
      const regex1 = new RegExp(regex?.slice(cuitSlice[0], cuitSlice[1]) + '+$');
      const cantRegex1 = parseInt(regex?.slice(cuitSlice[2], cuitSlice[3]));
      const regex2 = new RegExp('^' + regex?.slice(cuitSlice[4], cuitSlice[5]) + '+$');
      const cantRegex2 = parseInt(regex?.slice(-3, -2));
      return [...Array(cantRegex1).fill(regex1), ...Array(cantRegex2).fill(regex2)];
    }
    if (valueType === 'NIE') {
      const cuitSlice = [0, 9, 10, 11, 12, 17, 18, 19, 20, 28];
      const regex1 = new RegExp(regex?.slice(cuitSlice[0], cuitSlice[1]) + '+$');
      const cantRegex1 = parseInt(regex?.slice(cuitSlice[2], cuitSlice[3]));
      const regex2 = new RegExp('^' + regex?.slice(cuitSlice[4], cuitSlice[5]) + '+$');
      const cantRegex2 = parseInt(regex?.slice(cuitSlice[6], cuitSlice[7]));
      const regex3 = new RegExp('^' + regex?.slice(cuitSlice[8], cuitSlice[9]) + '+$');
      const cantRegex3 = parseInt(regex?.slice(-3, -2));
      return [...Array(cantRegex1).fill(regex1), ...Array(cantRegex2).fill(regex2), ...Array(cantRegex3).fill(regex3)];
    }
    if (valueType === 'CUIT' || valueType === 'CUIL') {
      const cuitSlice = [0, 6];
      const regex1 = new RegExp(regex?.slice(cuitSlice[0], cuitSlice[1]) + '+$');
      const cantRegex1 = parseInt(regex?.slice(-4, -2));
      return Array(cantRegex1).fill(regex1);
    }
  }
  return [];
};
