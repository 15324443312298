import React from 'react';
import { AddFieldProps } from '../types';
import { Field } from '../../field/field';

export const WorkAreaField = ({ fields, formik, schemas }: AddFieldProps) => {
  const workAreaList = [
    {
      id: 'Actividades de los hogares como empleadores de personal doméstico',
      value: 'Actividades de los hogares como empleadores de personal doméstico',
    },
    {
      id: 'Actividades financieras y seguros',
      value: 'Actividades financieras y seguros',
    },
    {
      id: 'Agencias de viajes e inmobiliarias',
      value: 'Agencias de viajes e inmobiliarias',
    },
    {
      id: 'Agricultura, ganadería, silvicultura y pesca',
      value: 'Agricultura, ganadería, silvicultura y pesca',
    },
    {
      id: 'Arte, joyería y banco de empeños',
      value: 'Arte, joyería y banco de empeños',
    },
    {
      id: 'Automoción y reparación de vehículos',
      value: 'Automoción y reparación de vehículos',
    },
    {
      id: 'Comercio al por mayor, al por menor, transporte y logística',
      value: 'Comercio al por mayor, al por menor, transporte y logística',
    },
    {
      id: 'Construcción',
      value: 'Construcción',
    },
    {
      id: 'Deporte, juegos de azar, entretenimiento y gastronomía',
      value: 'Deporte, juegos de azar, entretenimiento y gastronomía',
    },
    {
      id: 'Educación y ciencia',
      value: 'Educación y ciencia',
    },
    {
      id: 'Energia, servicios públicos y telecomunicaciones',
      value: 'Energia, servicios públicos y telecomunicaciones',
    },
    {
      id: 'Fabricación y producción industrial',
      value: 'Fabricación y producción industrial',
    },
    {
      id: 'Fideicomiso y organizaciones benéficas',
      value: 'Fideicomiso y organizaciones benéficas',
    },
    {
      id: 'Medios, tecnología y consultoría',
      value: 'Medios, tecnología y consultoría',
    },
    {
      id: 'Salud y bienestar',
      value: 'Salud y bienestar',
    },
    {
      id: 'Servicios legales, fiscal, auditoría y contabilidad',
      value: 'Servicios legales, fiscal, auditoría y contabilidad',
    },
    {
      id: 'Servicios públicos y sociales',
      value: 'Servicios públicos y sociales',
    },
  ];

  const workAreaField = fields?.workArea!;

  if (workAreaField?.required || workAreaField?.defaultValue) {
    schemas.occupation = schemas?.occupation!.required('El campo Sector en el que trabajas es requerido.');
  }

  return (
    <Field
      key="workArea"
      label="Sector en el que trabajas"
      required={workAreaField?.required}
      warning={formik?.errors.occupation}
      readOnly={workAreaField?.readOnly}
    >
      <select
        className="w-full appearance-none bg-transparent font-body text-base leading-tight text-primary focus:outline-none focus-visible:outline-none"
        value={formik?.values.workArea ?? ''}
        onChange={formik?.handleChange('workArea') || (() => ({}))}
        disabled={workAreaField?.readOnly}
      >
        <option value="" disabled>
          Seleccionar sector en el que trabajas
        </option>
        {workAreaList.map(({ id, value }: { id: string; value: string }) => (
          <option key={id} value={id}>
            {value}
          </option>
        ))}
      </select>
    </Field>
  );
};
